<div mat-dialog-title>{{ "tools.score.criteria.modal.title" | translate }}</div>
<p></p>
<div mat-dialog-content>
	<div class="flex flex-col">
		<mat-form-field appearance="outline">
			<mat-label>{{ "tools.score.criterion.label" | translate }}</mat-label>
			<input
				matInput
				[(ngModel)]="criterion.label" />
			<mat-hint align="end">{{ criterion.label?.length }} / 255</mat-hint>
		</mat-form-field>
		<mat-divider></mat-divider>
		<p></p>
		<div class="flex flex-row gap-x-4">
			<mat-form-field
				appearance="outline"
				class="flex-1">
				<mat-label>{{ "tools.score.option.label" | translate }}</mat-label>
				<input
					matInput
					[(ngModel)]="option.label" />
				<mat-hint align="end">{{ option.label?.length }} / 255</mat-hint>
			</mat-form-field>
			<mat-form-field
				appearance="outline"
				class="w-24">
				<mat-label>{{ "tools.score.option.value" | translate }}</mat-label>
				<input
					matInput
					[(ngModel)]="option.value" />
			</mat-form-field>
			<button
				mat-mini-fab
				color="primary"
				[disabled]="!option.label || !option.value"
				(click)="addOption()">
				<mat-icon>add</mat-icon>
			</button>
		</div>
		@for (option of criterion.options; track option; let last = $last) {
			<div class="flex flex-row gap-x-1 items-center">
				<button
					mat-icon-button
					(click)="deleteOption(option)">
					<mat-icon>delete</mat-icon>
				</button>
				<div>{{ option.label }}</div>
				<div>({{ option.value }})</div>
			</div>
			<mat-divider *ngIf="!last"></mat-divider>
		}
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.cancel" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		diameter="40"
		color="primary"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		*ngIf="!isLoading"
		[disabled]="!criterion.label || !criterion.options.length"
		(click)="create()">
		{{ "core.create" | translate }}
	</button>
</div>
