import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { MITPService } from "./mitp.service";
import { EScoreType, Score } from "../entities/tools/score/score";
import { Observable } from "rxjs";
import { ScoreReference } from "../entities/tools/score/score-reference";
import { ScoreCriterion } from "../entities/tools/score/score-criterion";

@Injectable({
	providedIn: "root",
})
export class ScoreService {
	private values = new Map<number, number>();

	constructor(
		private http: HttpClient,
		private mitp: MITPService
	) {}

	getScores(): Observable<Score[]> {
		return this.http.get<Score[]>(this.mitp.Scores());
	}

	addReference(score: Score, reference: ScoreReference): Observable<ScoreReference> {
		return this.http.post<ScoreReference>(this.mitp.Scores() + `/${score.id}/references`, reference);
	}

	create(score: Score): Observable<Score> {
		return this.http.post<Score>(this.mitp.Scores(), score);
	}

	update(score: Score): Observable<Score> {
		return this.http.post<Score>(this.mitp.Scores() + `/${score.id}`, score);
	}

	deleteReference(score: Score, reference: ScoreReference): Observable<HttpResponse<null>> {
		return this.http.delete<null>(this.mitp.Scores() + `/${score.id}/references/${reference.id}`, {
			observe: "response",
		});
	}

	createScoreCriterion(score: Score, criterion: ScoreCriterion): Observable<Score> {
		return this.http.post<Score>(this.mitp.Scores() + `/${score.id}/criteria`, criterion);
	}

	deleteCriterion(score: Score, criterion: ScoreCriterion): Observable<HttpResponse<null>> {
		return this.http.delete<null>(this.mitp.Scores() + `/${score.id}/criteria/${criterion.id}`, {
			observe: "response",
		});
	}

	calculate(score: Score, value: number, criterion: ScoreCriterion, isAdd: boolean = true): number {
		if (score.type === EScoreType.QCM) {
			const val = this.values.get(criterion.id);
			if (isAdd) {
				this.values.set(criterion.id, val ? val + value : value);
			} else {
				this.values.set(criterion.id, val ? val - value : -value);
			}
		} else {
			this.values.set(criterion.id, value);
		}
		if (score.type === EScoreType.QCS && this.values.size !== score.criteria.length) {
			return null;
		}
		return Array.from(this.values.values()).reduce((a, b) => a + b, 0);
	}

	clearValues(): void {
		this.values.clear();
	}
}
