<div mat-dialog-title>{{ "tools.score.create.modal.title" | translate }}</div>
<p></p>
<div mat-dialog-content>
	<div class="flex flex-col">
		<mat-form-field appearance="outline">
			<mat-label>{{ "tools.score.label" | translate }}</mat-label>
			<input
				matInput
				[(ngModel)]="score.label"
				required />
			<mat-hint align="end">{{ score.label?.length }} / 255</mat-hint>
			<mat-icon matIconPrefix>label</mat-icon>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>{{ "tools.score.type" | translate }}</mat-label>
			<mat-select [(value)]="score.type">
				<mat-option [value]="EScoreType.QCS">QCS</mat-option>
				<mat-option [value]="EScoreType.QCM">QCM</mat-option>
			</mat-select>
			<mat-icon matIconPrefix>label</mat-icon>
		</mat-form-field>
		<mat-accordion>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ "tools.score.description" | translate }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-panel-description>
					<editor
						class="w-full"
						style="height: 300px"
						[(ngModel)]="score.description"
						[init]="editorConfig"></editor>
				</mat-panel-description>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-panel-description>
					<div class="flex flex-col w-full">
						@for (item of score.criteria; track item; let last = $last) {
							<div class="flex flex-row gap-x-2 items-center">
								<button
									mat-icon-button
									(click)="deleteCriterion(item)">
									<mat-icon>delete</mat-icon>
								</button>
								<div>{{ item.label }} ({{ item.options.length }})</div>
							</div>
							<mat-divider *ngIf="!last"></mat-divider>
						}
					</div>
				</mat-panel-description>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ "tools.score.creteria.label" | translate }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-action-row>
					<button
						color="primary"
						mat-icon-button
						[disabled]="!score.id"
						(click)="addCriterion()">
						<mat-icon>add</mat-icon>
					</button>
				</mat-action-row>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{ "tools.score.references.label" | translate }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-panel-description>
					<div class="flex flex-col w-full">
						@for (reference of score.reference; track reference) {
							<div class="flex flex-row gap-x-2">
								<button
									mat-icon-button
									(click)="deleteReference(reference)">
									<mat-icon>delete</mat-icon>
								</button>
								<div>{{ reference.label }}</div>
							</div>
						}
					</div>
				</mat-panel-description>
				<mat-action-row>
					<button
						color="primary"
						mat-icon-button
						[disabled]="!score.id"
						(click)="addReference()">
						<mat-icon>add</mat-icon>
					</button>
				</mat-action-row>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		color="primary"
		diameter="40"
		*ngIf="isLoading"></mat-spinner>
	<button
		*ngIf="!isLoading && !score.id"
		mat-raised-button
		color="primary"
		(click)="create()">
		{{ "core.create" | translate }}
	</button>
	<button
		*ngIf="!isLoading && score.id"
		mat-raised-button
		color="primary"
		(click)="updateScore()">
		{{ "core.edit" | translate }}
	</button>
</div>
