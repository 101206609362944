import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from "@angular/material/dialog";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { MatButton, MatIconButton, MatMiniFabButton } from "@angular/material/button";
import { ScoreService } from "../../../../services/score.service";
import { ToastrService } from "ngx-toastr";
import { MatFormField, MatHint, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { ScoreCriterion } from "../../../../entities/tools/score/score-criterion";
import { MatDivider } from "@angular/material/divider";
import { ScoreOption } from "../../../../entities/tools/score/score-option";
import { MatIcon } from "@angular/material/icon";
import { NgIf } from "@angular/common";
import { Score } from "../../../../entities/tools/score/score";
import { finalize, first } from "rxjs";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Component({
	selector: "app-add-score-criterion",
	standalone: true,
	imports: [
		MatDialogTitle,
		NgxTolgeeModule,
		MatDialogContent,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		MatFormField,
		MatInput,
		FormsModule,
		MatLabel,
		MatDivider,
		MatMiniFabButton,
		MatIcon,
		MatIconButton,
		NgIf,
		MatProgressSpinner,
		MatHint,
	],
	templateUrl: "./add-score-criterion.component.html",
	styleUrl: "./add-score-criterion.component.scss",
})
export class AddScoreCriterionComponent {
	isLoading = false;
	criterion = new ScoreCriterion();
	option = new ScoreOption();

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: { score: Score; criterion: ScoreCriterion },
		private scoreService: ScoreService,
		private toastr: ToastrService,
		private translate: TranslateService,
		private dialogRef: MatDialogRef<AddScoreCriterionComponent>
	) {
		if (this.data.criterion) {
			this.criterion = this.data.criterion;
		}
	}

	create(): void {
		this.isLoading = true;
		this.scoreService
			.createScoreCriterion(this.data.score, this.criterion)
			.pipe(
				first(),
				finalize(() => {
					this.isLoading = false;
				})
			)
			.subscribe((response: Score) => {
				this.toastr.success(this.translate.instant("tools.score.criterion.create.success"));
				this.dialogRef.close(response);
			});
	}

	addOption(): void {
		this.criterion.options.push(this.option);
		this.option = new ScoreOption();
	}

	deleteOption(option: ScoreOption): void {
		this.criterion.options = this.criterion.options.filter((o) => o !== option);
	}
}
